<template>
  <div class="waitingForBuyersPayment">
    <Header :isGoBack="false" :title="title" theme="light" />
    <div class="zone__col">
      <div class="wrap__tips" v-if="isStatus == 3">
        <div class="box__img">
          <img src="../../assets/images/public/ic_exclamationmark.png" alt="" />
        </div>
        <div class="text_1">买家已付款</div>
      </div>
      <CountDown :minute="minute" :sec="sec" :text_1="text_1" :text_2="text_2" />
      <div class="wrap__text" v-if="isStatus == 3">
        <div class="text_1">
          买家已标记通过
          <span>{{ logData.channelName }}</span> 向您转帐，请您查收
        </div>
        <div class="text_2">
          如您未收到帐款或金额不对，请与买家沟通或在倒计时完成前点击申诉，否则将自动确认到账，如已到账，请点击确认到账
        </div>
      </div>
      <div class="wrap__content">
        <div class="content_2">
          <div class="flex-sb mb_8px">
            <div>
              买家名称 : <span>{{ logData.traderName }}</span>
            </div>
            <div class="save" v-if="isTraderRelations != true" @click="postTraderRelations({ traderId: logData.traderId })">
              {{ isTraderRelations ? "收藏" : "已收藏" }}
            </div>
          </div>
          <div class="mb_8px">
            订单金额 : <span>¥ {{ logData.orderAmount }}</span>
          </div>
          <div class="mb_8px" v-if="isStatus == 3">
            付款方式 : <span>¥ {{ logData.channelName }}</span>
          </div>
          <div class="flex-sb mb_2em">
            <div>
              订单编号 : <span>{{ logData.orderNo }}</span>
            </div>
            <div v-clipboard:copy="logData.orderNo" v-clipboard:success="handleCopySuccess" v-clipboard:error="handleCopyError" style="white-space:nowrap;">
              [复制]
            </div>
          </div>
        </div>
      </div>
      <!-- 底部 -->
      <div class="footer">
        <div
          @click="
            $router.push({
              path: `/message/${encodeURIComponent(logData.traderName)}/C2Ctrader_im_${logData.traderId}/trader_im_${logData.traderId}`,
            })
          "
        >
          <img src="../../assets/images/public/ic_payment_information.png" alt="" />
        </div>
        <div @click="payPhone(logData.userMobile)">
          <img src="../../assets/images/public/ic_payment_phone.png" alt="" />
        </div>
        <div v-if="isStatus == 3" class="box__btn">
          <div @click="$router.push(`/userAppeal/${logData.id}`)">
            去申诉
          </div>
          <div @click="checkPayMoneyPopup = true">
            确认到账
          </div>
        </div>
      </div>
    </div>
    <!-- 確認到款彈窗 -->
    <div class="checkPayMoneyPopup" v-if="checkPayMoneyPopup == true">
      <div class="container__main">
        <div class="title">重要提示</div>
        <div class="content">
          请您确认是否收到买家的付款，一旦确认无法更改。
        </div>
        <div class="box__btn">
          <div @click="checkPayMoneyPopup = false">取消</div>
          <div @click="checkBuyConfirm()">已到账</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import CountDown from "@/components/CountDown.vue";
import { Message } from "element-ui";
import { mapState, mapActions } from "vuex";
export default {
  name: "waitingForBuyersPayment",
  components: {
    Header,
    CountDown,
  },
  data() {
    return {
      title: "等待买家付款",
      data: [],
      totalSec: 1,
      minute: 0,
      sec: 0,
      timeCount: 0,
      timePush: 0,
      isTraderRelations: false,
      isStatus: false,
      text_1: "对方正在转帐",
      text_2: "99%的用户会在5分钟内转帐",
      checkPayMoneyPopup: false,
    };
  },
  computed: {
    ...mapState("orders", ["withdrawSubmitData", "logData", "countdown", "confirmData"]),
    ...mapState("trader", ["traderRelationsData"]),
    ...mapState("message", ["msgList", "isTimLogin"]),
  },
  watch: {
    totalSec: {
      handler(e) {
        if (e == 0) {
          clearInterval(this.timeCount);
          const self = this;
          self.timePush = setInterval(() => {
            self.getLogId(this.$route.query.orderId);
          }, 1000);
          if (this.title == "确认收款" && this.logData.status != 3) {
            this.$router.push({
              path: "/orderInfo",
              query: {
                orderId: this.logData.id,
              },
            });
          }
        }
      },
      deep: true,
    },
    ["logData.status"](e) {
      if (e == 4) {
        clearInterval(this.timePush);
        this.$router.push({
          path: "/orderInfo",
          query: {
            orderId: this.logData.id,
          },
        });
      }
    },
    countdown(e) {
      clearInterval(this.timeCount);
      this.time();
      this.totalSec = e["timeLeft"];
    },
    traderRelationsData() {
      this.findTraderRelations();
    },
    logData(e) {
      if (e.status == 3) {
        this.isStatus = e.status;
      }
    },
    isStatus(e) {
      if (e == 3) {
        let data = {
          orderNo: this.$route.query.orderNo,
        };
        this.title = "确认收款";
        this.text_1 = "";
        this.text_2 = "";
        this.getCountdown(data);
      }
    },
    confirmData() {
      this.$router.push({
        path: "/orderInfo",
        query: { orderId: this.logData.id },
      });
    },
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    ...mapActions("orders", ["postWithdrawSubmit", "getLogId", "getCountdown", "putBuyConfirm"]),
    ...mapActions("trader", ["getTraderRelations", "postTraderRelations"]),
    ...mapActions("message", ["getMsgList"]),
    init() {
      if (this.isTimLogin) this.getMsgList();
      let data = {
        orderNo: this.$route.query.orderNo,
      };
      this.getLogId(this.$route.query.orderId).then((res) => {
        if (res.data.abnormal == 1) {
          this.$router.push({
            path: "/appealDetail",
            query: { orderId: res.data.id },
          });
        }
      });
      this.getTraderRelations({});
      this.getCountdown(data).then((res) => {
        if (res.code == "fail") {
          this.$router.push({
            path: "/orderInfo",
            query: {
              orderNo: this.logData.orderNo,
              orderId: this.logData.id,
            },
          });
        }
      });
    },
    checkBuyConfirm() {
      this.putBuyConfirm({ orderNo: this.logData.orderNo });
    },
    payPhone(num) {
      location.href = `tel:${num}`;
    },
    time() {
      const self = this;
      if (self.totalSec > 0) {
        self.timeCount = setInterval(function() {
          self.totalSec = self.totalSec - 1;
          self.minute = parseInt(Math.floor(self.totalSec / 60));
          self.sec = parseInt(Math.floor(self.totalSec % 60));
        }, 1000);
      }
    },
    handleCopySuccess() {
      Message({
        message: "复制成功",
        iconClass: "x",
        center: true,
        customClass: "sucess_message",
      });
    },
    handleCopyError() {
      Message({
        message: "复制失败",
        iconClass: "x",
        center: true,
        customClass: "sucess_message",
      });
    },
    showPopup(e) {
      const self = this;
      if (e == false) {
        self.isTraderRelations = false;
      }
    },
    findTraderRelations() {
      const self = this;
      this.traderRelationsData.forEach((e) => {
        if (e.traderId == this.logData.traderId) {
          self.isTraderRelations = true;
        }
      });
    },
  },
  destroyed() {
    clearInterval(this.timeCount);
    clearInterval(this.timePush);
  },
};
</script>
<style lang="scss" scoped>
.zone__col {
  height: 100vh;
  overflow-y: scroll;
}
.wrap__content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  @media (min-width: 480px) {
    justify-content: center;
    padding-bottom: 4rem;
    overflow-y: scroll;
  }
  .content_2 {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    padding-top: 1.5rem;
    flex-wrap: wrap;
    font-size: 0.8rem;

    & > div {
      width: 100%;
      text-align: left;

      color: var(--gray);

      div {
        color: var(--gray);
      }

      span {
        color: var(--black);
      }

      .save {
        color: var(--orange);
      }
    }
  }
}

.wrap__tips {
  width: 100%;
  padding-top: 2rem;

  .box__img {
    width: 2rem;
    margin: 0 auto;
    font-size: 0;

    img {
      width: 100%;
    }
  }

  .text_1 {
    margin-top: 2px;
    color: var(--orange);
    font-size: 0.9rem;
  }
}

.wrap__text {
  width: 100%;

  .text_1 {
    font-size: 0.9rem;

    span {
      color: var(--orange);
    }
  }

  .text_2 {
    text-align: left;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    color: var(--light-black);
    font-size: 0.5rem;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  // left: 0;
  width: 100%;
  display: flex;
  // justify-content: flex-start;
  justify-content: space-around;
  align-items: center;
  // max-width: 414px;
  margin: 0 auto;
  // @media (min-width:480px){
  //   max-width: 414px;
  // }
  & > div {
    width: 100%;
  }

  & > div:nth-child(1),
  & > div:nth-child(2) {
    width: 10%;
    margin-left: 1rem;
    font-size: 0;

    img {
      width: 100%;
    }
  }

  .btn__cancel {
    background-color: var(--light-gray);
    color: var(--gray);
    margin-left: 1rem;
    width: 67%;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    font-size: 0.8rem;
  }

  .box__btn {
    width: 100%;
    display: flex;
    align-items: center;

    div:nth-child(1) {
      width: 50%;
      background-color: var(--light-gray);
      color: var(--gray);
      margin-left: 1rem;
      font-size: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    div:nth-child(2) {
      width: 50%;
      background-color: var(--orange);
      color: var(--white);
      font-size: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }
}

.checkPayMoneyPopup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 900;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .container__main {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: aliceblue;
    width: 80%;
    height: fit-content;
    padding: 2rem 1rem;
    border-radius: 15px;
    // max-width: 414px;
    @media (min-width: 480px) {
      max-width: unset;
    }
    .title {
      font-weight: bold;
      color: var(--orange);
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
    }

    .content {
      text-align: left;
      margin-bottom: 1.5rem;
    }

    .box__btn {
      display: flex;
      justify-content: space-around;

      div {
        width: 40%;
        padding: 0.8rem 0rem;
        color: aliceblue;
        border-radius: 20px;

        &:nth-child(1) {
          background-color: var(--light-black);
        }

        &:nth-child(2) {
          background-color: var(--orange);
        }
      }
    }
  }
}
</style>
