<template>
  <div class="CountDown">
    <div class="text_1">{{ text_1 }}</div>
    <div class="time">
      <span>{{ minute == 0 ? '-' : minute }}</span><span>分</span><span>{{ sec== 0 ? '-' : sec }}</span><span>秒</span>
    </div>
    <div class="text_2">
      {{ text_2 }}
    </div>
  </div>
</template>
<script>
export default {
  name: "CountDown",
  components: {},
  props: {
    minute: {
      type: Number
    },
    sec: {
      type: Number
    },
    text_1: {
      type: String,
      default: '订单已生成，请向卖家付款!'
    },
    text_2: {
      type: String,
      default: '99%的用户会在5分钟内付款'
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() { },
  mounted() { },
  methods: {},
};
</script>
<style lang="scss" scoped>
.CountDown {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;

  .text_1 {
    width: 100%;
    font-weight: bold;
  }

  .time {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    padding-top: 1rem;

    span:nth-child(1),
    span:nth-child(3) {
      padding: 0.3rem 0.6rem;
      border-radius: 5px;
      color: var(--white);
      background-color: var(--red);
      margin-left: 0.2rem;
      margin-right: 0.2rem;
    }

    span:nth-child(2),
    span:nth-child(4) {
      font-size: 1.2rem;
    }
  }

  .text_2 {
    padding-top: 1rem;
    width: 100%;
    color: var(--light-black);
    font-size: 0.5rem;
  }
}
</style>
